.about {
    &__col {
        width: 50%;
    }
    &__advantages {
        &-img,
        &-list {
            width: 50%;
        }
        &-item {
            padding: 13px 35px;
            text-decoration: underline;
            color: #fff;
            background-color: rgb(var(--dodgerBlue));
            border-radius: 4px;
            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }
    }
    .title {
        margin-bottom: 25px;
    }
}

@media (max-width: 992px) {
    .about {
        &__col {
            width: 100%;
            &:first-child {
                margin-bottom: 30px;
            }
        }
    }
}

@media (max-width: 575px) {
    .about {
        &__advantages {
            &-img,
            &-list {
                width: 100%;
            }
            &-img {
                margin-bottom: 30px;
                img {
                    margin: 0 auto;
                }
            }
            &-item {
                padding: 13px;
            }
        }
    }
}